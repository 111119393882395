<template>
  <div>
    <SingleColumn md="4" offset-md="4">
      <PCard class="reset-password-card mt-3 py-2">
        <ResetPassword
          v-if="screen === screenEnums.SET_PASSWORD"
          :email="email"
          :reset-code="resetCode"
          @submittedPassword="goTwoFA"
          @accountSuspended="handleSuspended"
        />
        <template v-if="screen === screenEnums.COMPLETED">
          <PRow class="justify-content-md-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('account:password.updated') }}
              </div>
              <div class="subtitle mt-3 mb-4">
                {{ $t('account:password.sign_in') }}
              </div>
              <PButton variant="dark" @click="goToLogin">
                {{ $t('account:login_now') }}
              </PButton>
            </PColumn>
          </PRow>
        </template>
        <template v-if="screen === screenEnums.EXPIRED">
          <PRow class="justify-content-md-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('common:sorry') }}
              </div>
              <div class="subtitle mt-3 mb-4">
                {{ $t('account:password_reset.expired') }}
              </div>
              <PButton variant="dark" @click="resendLink">
                {{ $t('account:resend_email') }}
              </PButton>
            </PColumn>
          </PRow>
        </template>

        <template v-if="screen === screenEnums.INVALID">
          <PRow class="justify-content-md-center">
            <PColumn>
              <div class="subtitle bold">
                {{ $t('common:sorry') }}
              </div>
              <div class="subtitle mt-3 mb-4">
                {{ $t('account:password_reset.invalid') }}
              </div>
              <PButton variant="dark" @click="resendLink">
                {{ $t('account:resend_email') }}
              </PButton>
            </PColumn>
          </PRow>
        </template>
      </PCard>
    </SingleColumn>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'

const screenEnums = {
  SET_PASSWORD: 'resetPassword',
  EXPIRED: 'expired',
  INVALID: 'invalid',
  COMPLETED: 'completed'
}

export default {
  name: 'ResetPasswordPage',
  data() {
    return {
      resetCode: '',
      email: '',
      screen: '',
      screenEnums
    }
  },
  async mounted() {
    const { details } = this.$route.query
    if (!details) {
      this.screen = screenEnums.INVALID
      return
    }
    const decodeDetails = JSON.parse(Base64.decode(details))
    const { email, reset_password_code: resetCode } = decodeDetails
    if (!resetCode || !email) {
      this.screen = screenEnums.INVALID
      return
    }
    this.resetCode = resetCode
    this.email = email
    this.screen = screenEnums.SET_PASSWORD
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'LoginPage' })
    },
    goTwoFA() {
      this.$router.push({ name: 'TwoFAPage' })
    },
    resendLink() {
      this.$router.push({ name: 'ForgotPasswordPage' })
    },
    handleSuspended() {
      this.$router.push({ name: 'AccountSuspendedPage' })
    },
    verify(success) {
      if (success) {
        this.screen = screenEnums.COMPLETED
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password-card {
  width: 432px;
}
</style>
