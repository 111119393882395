<template>
  <SubAdvisorConsentForm
    :subAdvisorPmData="subAdvisorPmData"
    :clientId="clientId"
    @after-submit="afterSubmit"
    @disagree="onDisagree"
  />
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapActions } = createNamespacedHelpers('consent')
const { mapState } = createNamespacedHelpers('user')

export default {
  name: 'SubAdvisorConsentPage',
  computed: {
    ...mapGetters([
      'clientId',
      'consent/subAdvisorConsentRequired'
    ]),
    ...mapState(['userInfo']),
    subAdvisorPmData () {
      return {
        subAdvisorName: this.userInfo?.sub_advisor_legal_name ? this.userInfo?.sub_advisor_legal_name : this.userInfo?.sub_advisor_name,
        subAdvisorID: this.userInfo?.sub_advisor_id,
        pmContactInfo: this.userInfo?.pm_email,
        shortName: this.userInfo?.sub_advisor_short_name,
        pmId: this.userInfo?.pm_id
      }
    }
  },
  methods: {
    ...mapActions([
      'initUserConsent'
    ]),
    afterSubmit() {
      this.$router.push({ name: 'Home' })
    },
    onDisagree() {
      this.$router.push({ name: 'SubAdvisorConsentDisagreePage' })
    }
  },
  async mounted() {
    await this.initUserConsent()
    console.log(!this.subAdvisorConsentRequired)
    if (!this.subAdvisorConsentRequired) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
