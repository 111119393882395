import jwtDecode from 'jwt-decode'
import { identifyUser } from '../../services/logRocket'

const state = {
  user: null,
  userInfo: null,
  featureFlags: null,
  bootstrapLoaded: false,
  onboardingState: null
}

const mutations = {
  SET_USER_INFO (state, info) {
    state.userInfo = info
  },
  SET_FEATURE_FLAGS (state, flags) {
    state.featureFlags = flags
  },
  SET_BOOTSTRAP_LOADED (state, value) {
    state.bootstrapLoaded = value
  },
  SET_ONBOARDING_STATE (state, value) {
    state.onboardingState = value
  }
}

const actions = {
  async bootstrapCall ({ rootState, state, commit }) {
    if (state.bootstrapLoaded) { return }
    const info = await rootState.api.dataHub.getInvestorInfo()
    commit('SET_USER_INFO', info)
    const featureFlags = (await rootState.api.dataHub.featureFlags()).results[0]
    commit('SET_FEATURE_FLAGS', featureFlags)
    commit('SET_BOOTSTRAP_LOADED', true)
  },
  async identifyLogRocketUser ({ rootState, state }) {
    const token = rootState.api.auth.localStorageService.getAccessToken()
    if (state.userInfo && token) {
      const decodeToken = jwtDecode(token)
      const clientId = decodeToken.client_id
      await identifyUser({
        client_id: clientId,
        name: `${state.userInfo.investor_first_name} ${state.userInfo.investor_last_name}`,
        email: state.userInfo.investor_email,
        firm: rootState?.context?.firm
      })
    }
  },
  resetSurveyStatus({ commit }) {
    commit('SET_ONBOARDING_STATE', null)
  },
  async getSurveyStatus ({ rootState, _state, commit }) {
    const onboardingState = await rootState.api.dataCapture.fetchDataCaptureStatus()
    commit('SET_ONBOARDING_STATE', onboardingState)
  },
  clearUserInfo ({ commit }) {
    commit('SET_USER_INFO', null)
    commit('SET_FEATURE_FLAGS', null)
    commit('SET_BOOTSTRAP_LOADED', false)
    commit('SET_ONBOARDING_STATE', null)
  },
  clearAccessToken ({ rootState }) {
    rootState.api.auth.localStorageService.clearToken()
    rootState.api.auth.localStorageService.clearJwt()
  },
  async getCalendlyUserLink ({ rootState }, { pmId }) {
    const res = await rootState.api.dataHub.getInvestorPmCalendlyLink({ platform_user_id: pmId })
    return res.calendly_link
  }
}

const getters = {
  isAnnualKycRequired: (s) => s.onboardingState?.survey === 'annual_kyc' && s.onboardingState?.survey_status !== 'completed',
  isActiveClient: (s) => s.userInfo?.investor_client_status && s.userInfo.investor_client_status === 'active',
  clientMaintenanceEnabled: (s) => s.featureFlags && s.featureFlags.client_maintenance === true
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
