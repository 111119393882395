import Vue from 'vue'
import { getAuth0PluginOptions } from './options'

function getPluginOptions(pluginName) {
  const pluginOptions = {
    Auth0: getAuth0PluginOptions()
  }
  return pluginOptions[pluginName]
}

export function installPlugin(pluginName, plugin) {
  Vue.use(plugin, getPluginOptions(pluginName))
}
