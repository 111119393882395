/* eslint-disable vue/component-definition-name-casing */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import Locize from 'i18next-locize-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import VueI18Next from '@panter/vue-i18next'
import HttpApi from 'i18next-http-backend'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { importAndRegisterModules } from './services/boot'
import Purposeful from '@purposeadvisorsolutions/purposeful'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.use(Purposeful)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18Next)

i18next.use(ChainedBackend).init({
  ns: [
    'account',
    'account_creation',
    'address_types',
    'cm_common',
    'cm_module',
    'common',
    'consent',
    'countries',
    'datacapture',
    'document_manager',
    'homepage',
    'identification_dual_source_types',
    'identification_types',
    'legal_entity',
    'login',
    'nav',
    'provinces',
    'relationships',
    'salutation_types',
    'identification_types',
    'select_options',
    'sub_advisor_consent'
  ],
  supportedLngs: ['en-CA', 'fr-CA'],
  fallbackLng: 'en-CA',
  load: 'currentOnly',
  debug: false,
  saveMissing: false,
  backend: {
    backends: [
      LocalStorageBackend,
      Locize,
      HttpApi
    ],
    backendOptions: [{
      expirationTime: 0.5 * 24 * 60 * 60 * 1000 // 12 hours
    },
    {
      projectId: process.env.VUE_APP_LOCIZE_PROJECT_ID,
      version: process.env.VUE_APP_LOCIZE_BRANCH_NAME,
      referenceLng: 'en-CA'
    },
    {
      loadPath: `https://s3.ca-central-1.amazonaws.com/locize-translations/investor-portal/${process.env.VUE_APP_LOCIZE_BRANCH_NAME}/{{lng}}/{{ns}}.json` // http api load path for my own fallback
    }]
  }
})

const i18n = new VueI18Next(i18next)

library.add(fal)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

importAndRegisterModules().then(_ => {
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
