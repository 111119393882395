import Vue from 'vue'
import serviceRegistry from '../serviceRegistry'
import assetRegistry from '../../assets/registry'
import { installPlugin } from '../pluginRegistry'
import { setGlobalMixins } from './mixins'

// const localPurposeful = process.env.LOCAL_PURPOSEFUL
// const localFeShared = process.env.VUE_APP_LOCAL_FE_SHARED
// const localOverture = process.env.VUE_APP_LOCAL_OVERTURE

export async function importAndRegisterModules () {
  try {
    const FeShared = (await import('@purposeadvisorsolutions/features')).default

    // if (localOverture === 'true') var OvertureComponents = (await import('../../../links/overture/components/bundle.js')).default

    Object.keys(FeShared.Services).forEach(k => {
      serviceRegistry.registerService(k, FeShared.Services[k])
    })

    Object.keys(FeShared.Assets).forEach(k => {
      assetRegistry.registerAsset(k, FeShared.Assets[k])
    })

    // register these in main.js if pulling from Bit
    Object.keys(FeShared.Components).forEach(k => {
      Vue.component(k, FeShared.Components[k])
    })

    Object.entries(FeShared.Plugins).forEach(([k, v]) => {
      installPlugin(k, v)
    })

    setGlobalMixins(Vue)
  } catch (e) {
    console.error(e)
  } finally {
    // return
  }
}
