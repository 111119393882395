<template>
  <div>
    <PContainer class="mt-3 mb-5">
      <PRow
        v-if="verificationStatus === VerificationStatussesEnum.Success"
        class="justify-content-md-center text-center"
      >
        <PColumn md="6">
          <div class="subtitle mt-3 mb-4">
            {{ $t('account_creation:email_verification_status.success.label') }}
          </div>
          <PButton @click="$router.push({ name: 'loginPage' })" class="px-3">
            {{ $t('nav:login') }}
          </PButton>
        </PColumn>
      </PRow>
      <PRow
        v-if="verificationStatus === VerificationStatussesEnum.Fail"
        class="justify-content-md-center text-center"
      >
        <PColumn md="6">
          <div class="subtitle mt-3 mb-4">
            <div v-if="specificErrorMessage">
              {{ $t(specificErrorMessage) }}
              <br>
            </div>
            <div v-else>
              {{ $t('account_creation:email_verification_status.fail.label') }}
            </div>
            {{ updateEmail ? $t('account_creation:email_verification_status.fail.contact_support') : $t('account_creation:email_verification_status.fail.sign_in') }}
          </div>
          <PButton @click="$router.push({ name: 'loginPage' })" class="px-3">
            {{ $t('nav:login') }}
          </PButton>
        </PColumn>
      </PRow>
      <PRow class="justify-content-md-center text-center mt-3">
        <VerifyEmail
          @fail="fail"
          @success="success"
          :client-id="clientId"
          :email="email"
          :activation-code="activationCode"
          :update-email="updateEmail"
        />
      </PRow>
    </PContainer>
  </div>
</template>

<script>
import { identifyUser } from '../../services/logRocket'
import { Base64 } from 'js-base64'

const VerificationStatussesEnum = {
  Success: 'Success',
  Fail: 'Fail'
}

export default {
  name: 'VerifyEmailPage',
  data() {
    return {
      clientId: null,
      email: '',
      activationCode: '',
      verificationStatus: '',
      VerificationStatussesEnum,
      updateEmail: false,
      specificErrorMessage: null
    }
  },
  async mounted() {
    const { details } = this.$route.query
    try {
      const decodeDetails = JSON.parse(Base64.decode(details))
      const { email, activation_code: activateCode, client_id: clientId, updateEmail } = decodeDetails
      this.clientId = this.authUser && this.authUser.client_id ? this.authUser.client_id : clientId
      this.email = this.authUser && this.authUser.email ? this.authUser.email : email
      this.activationCode = activateCode
      this.updateEmail = updateEmail || false
      if (!this.clientId || !this.email || !this.activationCode || !this.firm) {
        this.verificationStatus = VerificationStatussesEnum.Fail
      }
      identifyUser({ client_id: clientId, email, firm: this.firm })
    } catch (e) {
      this.verificationStatus = VerificationStatussesEnum.Fail
    }
  },
  methods: {
    success () {
      this.verificationStatus = VerificationStatussesEnum.Success
      this.specificErrorMessage = null
    },
    fail (message) {
      this.specificErrorMessage = message
      this.verificationStatus = VerificationStatussesEnum.Fail
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
