<template>
  <SingleColumn md="4" offset-md="4">
    <PRow class="justify-content-center">
      <PColumn>
        <div class="subtitle bold my-3">
          {{ $t("account_creation:email_verification.helper_text") }}
        </div>
        <PButton
          variant="dark"
          :disabled="resendButtonDisabled"
          @click="resendVerifyEmail"
          id="verify-email-resend-email-btn"
        >
          {{ $t("account_creation:email_verification.resend_email.button_text") }}
          <PSpinner v-if="resendButtonDisabled" small />
        </PButton>
      </PColumn>
    </PRow>
  </SingleColumn>
</template>

<script>
export default {
  name: 'EmailVerificationPending',
  data () {
    return {
      resendButtonDisabled: false
    }
  },
  async created () {
    await this.resendVerifyEmail()
  },
  methods: {
    async resendVerifyEmail () {
      this.resendButtonDisabled = true
      try {
        await this.$store.dispatch('auth/sendVerificationEmail')
        this.$store.dispatch('notification/showNotification', {
          type: 'info',
          message: this.$t('account_creation:email_verification.notification_text')
        })
      } catch (e) {
        console.error(e)
        this.$store.dispatch('notification/showNotification', {
          type: 'error',
          message: this.$t('common:try_again_later')
        })
      } finally {
        this.resendButtonDisabled = false
      }
    }
  }
}
</script>
