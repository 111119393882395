import LogRocket from 'logrocket'

function isSensitiveRequest (requestUrl) {
  if (requestUrl.includes('password') || requestUrl.includes('login')) {
    return true
  } else if (requestUrl.includes('registration') && !requestUrl.includes('email')) {
    return true
  }
  return false
}

export function initLogRocket () {
  if (process.env.VUE_APP_LOGROCKET_ENABLED !== 'true') {
    // console.log('LogRocket not enabled')
    return
  }
  // console.log('initializing LogRocket session')
  LogRocket.init(process.env.VUE_APP_LOGROCKET_APP_ID, {
    network: {
      requestSanitizer: (request) => {
        const requestUrl = request.url.toLowerCase()
        if (isSensitiveRequest(requestUrl)) {
          request.body = null
        }
        return request
      }
    }
  })
}

export function identifyUser ({ client_id: clientId, name, email, firm }) {
  if (process.env.VUE_APP_LOGROCKET_ENABLED !== 'true') {
    // console.log('LogRocket not enabled')
    return
  }
  LogRocket.log('identifying LogRocket user: ', { clientId, name, email, firm })
  LogRocket.identify(clientId, {
    name,
    email,
    firm
  })
}
