<template>
  <div id="app">
    <LanguageSwitcher class="mr-2 mt-2 float-right" v-if="!showLogoHeader && localizationEnabled" />
    <PModal :visible="idleDialog" centered no-close-on-backdrop :hide-backdrop="false">
      <h3>{{ $t('login:inactivity.header') }}</h3>
      <p class="mb-2">
        {{ $t('login:inactivity.text') }}
      </p>
      <PButton href="/login" class="px-3">
        {{ $t('login:signIn_text') }}
      </PButton>
    </PModal>
    <AuthWrapper>
      <Consent>
        <BaseLayout :has-nav="showLogoHeader" :banner="banner" @click="navigateToSurvey()">
          <template #nav>
            <LogoutHeader
              v-if="showLogoHeader"
              @logout="logOut"
              @logoClick="logoClick"
              @navItemClick="itemClick"
              :onauth="onAuthRoute"
              :showNavItems="onAuthRoute && !onConsentPage"
              :navItems="navItems"
            />
          </template>
          <template #main>
            <router-view />
          </template>
        </BaseLayout>
        <NotificationWrapper />
      </Consent>
    </AuthWrapper>
  </div>
</template>

<script>
import assets from '../src/assets/registry'
import { initLogRocket } from '../src/services/logRocket'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('user')
const { mapGetters: contextGetters } = createNamespacedHelpers('context')

export default {
  name: 'App',
  data() {
    return {
      loading: true,
      hasBeenIdleTooLong: false
    }
  },
  methods: {
    logOut (success) {
      if (success) {
        this.$router.push({ name: 'loginPage' })
        this.$store.dispatch('user/clearUserInfo')
      } else {
        console.error('Could not logout')
      }
    },
    logoClick () {
      if (this.$route.meta.requiresAuth !== false) {
        this.$router.push({ name: 'Home' })
      } else {
        this.$router.push({ name: 'loginPage' })
      }
    },
    itemClick (item) {
      item.callback()
    },
    async setLocalizationSupport () {
      const res = await this.$store.state.api.auth.localizationSupport({ firm: this.firm })
      this.$store.dispatch('setLocalizationSupport', { localizationSupport: res.multilingual_ip })
    },
    navigateToSurvey () {
      this.$router.push({ path: '/survey', query: { started: true } })
    },
    setIdleTooLong() {
      this.hasBeenIdleTooLong = true
    }
  },
  watch: {
    firm: {
      immediate: true,
      handler: function (val) {
        // if (!val) this.$router.push({ name: 'GenericErrorPage' })
      }
    },
    firmDisplayName: {
      handler(value) {
        if (value) {
          document.title = value
        }
      }
    },
    idleDialog: {
      handler(value) {
        if (value) {
          this.$store.dispatch('user/clearUserInfo')
          this.$store.dispatch('user/clearAccessToken')
          this.setIdleTooLong()
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'clientMaintenanceEnabled',
      'isActiveClient'
    ]),
    ...contextGetters(['firmDisplayName']),
    banner () {
      const isAnnualKycRequired = this.$store.getters['user/isAnnualKycRequired']
      const annualKycBanner = {
        text: this.$t('datacapture:survey.akyc.banner.text'),
        appendIcon: 'circle-chevron-right',
        variant: 'danger'
      }

      return isAnnualKycRequired ? annualKycBanner : null
    },
    navItems () {
      const items = [
        {
          text: 'Home',
          icon: 'home',
          active: this.$route.name === 'Home' || this.$route.name === 'DcSurveyPage',
          callback: () => {
            if (this.$route.name !== 'Home') {
              this.$router.push({ name: 'Home' })
            }
          }
        }
        // Disabled due to this request from Lara https://pas.tpondemand.com/restui/board.aspx?#page=feature/33781 -ET
        // {
        //   text: 'My Documents',
        //   icon: 'file',
        //   active: this.$route.name === 'MyDocumentsPage',
        //   callback: () => {
        //     if (this.$route.name !== 'MyDocumentsPage') {
        //       this.$router.push({ name: 'MyDocumentsPage' })
        //     }
        //   }
        // }
      ]
      if (this.onAuthRoute &&
        this.$store.getters['user/clientMaintenanceEnabled'] &&
        this.$store.getters['user/isActiveClient']) {
        items.push({
          text: 'Client Maintenance',
          icon: 'user-edit',
          active: this.$route.name === 'DcClientMaintenancePage',
          callback: () => {
            if (this.$route.name !== 'DcClientMaintenancePage') {
              this.$router.push({ name: 'DcClientMaintenancePage' })
            }
          }
        })
      }
      return items
    },
    showLogoHeader () {
      return this.$route.meta.showHeader !== false
    },
    onAuthRoute () {
      return this.$route.meta.requiresAuth !== false
    },
    onConsentPage () {
      return this.$route.name === 'ConsentPage'
    },
    localizationEnabled () {
      return this.$store.getters.localizationSupport
    },
    idleDialog() {
      if (this.loading) return false
      if (!this.isAppIdle && !this.hasBeenIdleTooLong) return false
      return true
    }
  },
  created () {
    if (this.firm) {
      const faviconSelector = document.querySelector('link[rel="icon"]')
      if (faviconSelector && assets.firms[this.firm].icon) {
        faviconSelector.href = assets.firms[this.firm].icon
      }
      initLogRocket()
      this.setLocalizationSupport()
    }
    this.loading = false
  }
}
</script>

<style lang="scss">
@import "./styles/app.scss";
</style>
