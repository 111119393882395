import Vue from 'vue'
import Vuex from 'vuex'
import IdleVue from 'idle-vue'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    api: null,
    authService: null,
    localizationSupport: null
  },
  mutations: {
    SET_API (state, api) {
      state.api = api
    },
    SET_AUTH_SERIVCE (state, auth) {
      state.authService = auth
    },
    SET_LOCALIZATION_SUPPORT (state, localizationSupport) {
      state.localizationSupport = localizationSupport
    }
  },
  actions: {
    setAPI ({ commit }, { api }) {
      commit('SET_API', api)
    },
    setAuthService ({ commit }, { auth }) {
      commit('SET_AUTH_SERIVCE', auth)
    },
    setLocalizationSupport ({ commit }, { localizationSupport }) {
      commit('SET_LOCALIZATION_SUPPORT', localizationSupport)
    }
  },
  getters: {
    clientId: (s) => s.api && s.api.auth.localStorageService.getClientId(),
    localizationSupport: (s) => s.localizationSupport
  },
  modules: {
    user
  }
})

// The following sets the idle timeout/expiration for the application session
let idleTime = 15 * 60 * 1000 // 15 minutes for production

if (process.env.ENVIRONMENT === 'local' || process.env.ENVIRONMENT === 'dev') {
  // idleTime = 120 * 60 * 1000 // 2 hour for development
  idleTime = 120 * 60 * 1000 // 2 hour for development
}

Vue.use(IdleVue, {
  store,
  idleTime,
  startAtIdle: false
})

export default store
