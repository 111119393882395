<template>
  <SubAdvisorConsentDisagree
    :investorInfo="investorInfo"
    @exit="onExit"
    :getCalendlyLink="getCalendlyLink"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('user')

export default {
  name: 'SubAdvisorConsentDisagreePage',
  computed: {
    ...mapState(['userInfo']),
    investorInfo () {
      return {
        firstName: this.userInfo?.investor_first_name,
        lastName: this.userInfo?.investor_last_name,
        email: this.userInfo?.investor_email
      }
    }
  },
  methods: {
    ...mapActions(['getCalendlyUserLink']),
    async getCalendlyLink() {
      return await this.getCalendlyUserLink({ pmId: this.userInfo.pm_id })
    },
    onExit() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
