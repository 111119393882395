<template>
  <HomePage :welcomeMessage1="welcomeMessage1" :welcomeMessage2="welcomeMessage2" :messageParams="messageParams">
    <template v-for="(card, index) in homepageData">
      <HomePageCard
        v-if="card.display"
        :key="index"
        :title="$t(card.title)"
        :description="$t(card.description, card.descriptionParams)"
        :icon="card.icon"
        :iconBgColour="card.iconBgColour"
        :clickable="card.clickable"
        @click="card.event"
      />
    </template>
  </HomePage>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapState } = createNamespacedHelpers('user')

export default {
  name: 'HomeView',
  data() {
    return {
      welcomeMessage1: 'homepage:welcome_message_line_1',
      welcomeMessage2: 'homepage:welcome_message_line_2',
      messageParams: { first_name: this.$store.state.user.userInfo.investor_first_name },
      homepageData: [
        {
          title: 'homepage:personal_details_title',
          description: 'homepage:personal_details_description',
          icon: 'user',
          iconBgColour: '#E7EDD0',
          event: () => this.$router.push({ name: 'DcClientMaintenancePage' }),
          display: this.$store.getters['user/isActiveClient'] && this.$store.getters['user/clientMaintenanceEnabled']
        },
        {
          title: 'homepage:your_files_title',
          description: 'homepage:your_files_description',
          icon: 'file',
          iconBgColour: '#DDD6E0',
          event: () => this.$router.push({ name: 'MyDocumentsPage' }),
          display: false // Disabled due to Lara request https://pas.tpondemand.com/restui/board.aspx?#page=feature/33781 -ET
        },
        {
          title: 'homepage:d1g1t_title',
          description: 'homepage:d1g1t_description',
          icon: 'chart-mixed',
          iconBgColour: '#DAD1D0',
          event: () => {
            if (this.$store?.state?.context?.firmDetails?.d1g1t_investor_link) {
              window.open(this.$store.state.context.firmDetails.d1g1t_investor_link, '_blank', 'rel="noopener"').focus()
            }
          },
          display: this.$store?.state?.context?.firmDetails?.d1g1t_investor_link
        },
        {
          title: 'homepage:need_help_title',
          description: 'homepage:need_help_description',
          descriptionParams: {
            first_name: this.$store.state.user.userInfo.advisor_first_name,
            last_name: this.$store.state.user.userInfo.advisor_last_name,
            email: this.$store.state.user.userInfo.advisor_email
          },
          icon: 'circle-question',
          iconBgColour: '#E1DCD4',
          clickable: false,
          event: () => {},
          display: true
        }
      ]
    }
  },
  computed: {
    ...mapState(['bootstrapLoaded']),
    ...mapGetters(['clientMaintenanceEnabled', 'user/isActiveClient']),
    theme() {
      return this.$store.state.theme.theme
    },
    userFirstName() {
      return this.$store.state.user.userInfo.investor_first_name
    }
  }
}
</script>
