<template>
  <div>
    <SingleColumn md="4" offset-md="4">
      <PCard>
        <LoginForm
          @login="loginLocal($event)"
          @passwordReset="$router.push({ name: 'ForgotPasswordPage' })"
        >
          <!-- When SSO enabled, this will replace local login form and related logic -->
          <template #content v-if="$auth.isFirmSSOActivated(firm)">
            <PForm id="sso-login-form" data-testid="sso-login-form">
              <PRow class="justify-content-md-center text-center">
                <PColumn>
                  <PButton
                    variant="primary"
                    type="button"
                    size="lg"
                    @click="loginSSO()"
                    :loading="loginProcessing"
                    :disabled="loginProcessing"
                  >
                    {{ $t("login:signIn_text") }}
                  </PButton>
                </PColumn>
              </PRow>
            </PForm>
          </template>
        </LoginForm>
      </PCard>
    </SingleColumn>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('auth')

export const UserStatusEnum = {
  CREATED_PASSWORD: 1,
  TWO_FA_CODE_SENT: 2,
  TWO_FA_CODE_VERIFIED: 3,
  CHALLENGE_ANSWERS_CONFIGURED: 4,
  EMAIL_VERIFIED: 5,
  SUSPENDED: 6
}

export default {
  name: 'LoginPage',
  data () {
    return {
      loginProcessing: false
    }
  },
  mounted () {
    if (this.$route.name === 'GrantPage') {
      this.handleSSOGrant()
    }
  },
  methods: {
    async loginLocal (e) {
      if (e.success) {
        if (this.user.type === 'advisor') {
          this.$router.push({ name: 'DcSurveyPage' })
          return
        }
        const res = await this.$store.dispatch('auth/checkUserStatus')
        if (res.status === UserStatusEnum.SUSPENDED) {
          this.$router.push({ name: 'AccountSuspendedPage' })
        } else if (res.status < UserStatusEnum.EMAIL_VERIFIED) {
          this.$router.push({ name: 'TwoFASetupPage' })
        } else {
          this.$router.push({ name: 'TwoFAPage' })
        }
      } else {
        if (e.detail?.includes('suspended')) {
          this.$router.push({ name: 'AccountSuspendedPage' })
        }
      }
    },
    async loginSSO () {
      try {
        this.loginProcessing = true

        const codeVerifier = this.$auth.createCodeVerifier()
        localStorage.setItem('code_verifier', codeVerifier)

        const callbackURL = window.location.origin + '/grant/'
        localStorage.setItem('redirect_uri', callbackURL)

        let organizationId
        try {
          const auth0OrganizationIds = process.env.VUE_APP_AUTH0_ORGANIZATION_IDS ?? {}
          organizationId = JSON.parse(auth0OrganizationIds)[this.firm]
        } catch (e) {
          throw new Error('Missing organization information.', e)
        }

        if (organizationId) {
          try {
            const loginParams = {
              authorizationParams: {
                redirect_uri: callbackURL,
                code_verifier: codeVerifier,
                organization: organizationId
              }
            }
            await this.$auth.loginWithRedirect(loginParams)
          } catch (e) {
            throw new Error('Login failed: ' + String(e))
          }
        }
      } catch (e) {
        this.loginProcessing = false
        this.$store.dispatch('notification/showNotification', {
          type: 'error',
          message: this.$t('login:toasts.error.text.unableLogin')
        })
        throw e
      }
    },
    async handleSSOGrant () {
      this.loginProcessing = true

      const firm = this.firm
      const { code, state } = this.$route.query
      const codeVerifier = localStorage.getItem('code_verifier')
      const redirectUri = localStorage.getItem('redirect_uri')
      const clientId = this.$auth.clientId

      try {
        if (!code || !state) {
          this.$router.push({ name: 'LoginPage' })
        } else {
          const res = await this.$store.dispatch('auth/loginSSO', {
            clientId,
            code,
            codeVerifier,
            firm,
            redirectUri,
            state
          })

          if (res.status === 'success') {
            this.$router.push({ name: 'Home' })
          } else {
            this.$router.push({ name: 'LoginPage' })
          }
        }
      } catch (e) {
        this.$store.dispatch('notification/showNotification', {
          type: 'error',
          message: this.$t('login:toasts.error.text.unableLogin')
        })
      } finally {
        this.loginProcessing = false
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>

</style>
