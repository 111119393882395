<template>
  <SingleColumn md="4" offset-md="4">
    <ForgotPassword />
  </SingleColumn>
</template>

<script>

export default {
  name: 'ForgotPasswordPage'
}
</script>

<style lang="scss" scoped>

</style>
