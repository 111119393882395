<template>
  <div>
    <DataCaptureClientMaintenance
      v-if="bootstrapLoaded && clientId"
      :clientId="clientId"
      :navigation="navigation"
      :requiresChangeRequest="true"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('user')

export default {
  name: 'ClientMaintainancePage',
  computed: {
    ...mapState(['bootstrapLoaded']),
    ...mapGetters(['clientId', 'user/isActiveClient']),
    navigation() {
      return {
        // no navigation logic needed
      }
    }
  },
  methods: {
    ...mapActions(['bootstrapCall', 'identifyLogRocketUser'])
  },
  watch: {
    bootstrapLoaded: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.identifyLogRocketUser()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
