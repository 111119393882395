export const setGlobalMixins = (vueClass) => {
  vueClass.mixin({
    computed: {
      firm () {
        return this.$store.state.context && this.$store.state.context.firm
      },
      authUser() {
        return this.$store.state.auth && this.$store.state.auth.user
      }
    }
  })
}
