<template>
  <div>
    <DataCaptureSurvey
      :clientId="clientId"
      :investor="investor"
      :advisor="advisor"
      :featureFlags="featureFlags"
      v-if="bootstrapLoaded"
      viewMode="investor"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('user')
export default {
  name: 'DcSurveyPage',
  computed: {
    ...mapState(['bootstrapLoaded', 'userInfo', 'featureFlags']),
    ...mapGetters(['clientId', 'user/isActiveClient']),
    investor () {
      return Object.keys(this.userInfo).reduce((investorInfo, key) => {
        if (key.startsWith('investor_')) {
          const trimmedKey = key.slice('investor_'.length)
          investorInfo[trimmedKey] = this.userInfo[key]
        }
        return investorInfo
      }, {})
    },
    advisor () {
      return Object.keys(this.userInfo).reduce((advisorInfo, key) => {
        if (key.startsWith('advisor_')) {
          const trimmedKey = key.slice('advisor_'.length)
          advisorInfo[trimmedKey] = this.userInfo[key]
        }
        return advisorInfo
      }, {})
    }
  },
  created () {
    this.resetSurveyStatus()
  },
  methods: {
    ...mapActions(['bootstrapCall', 'identifyLogRocketUser', 'resetSurveyStatus'])
  },
  watch: {
    bootstrapLoaded: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.identifyLogRocketUser()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
