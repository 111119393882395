import _ from 'lodash'
import router, { routes } from '../../router'

const noAuthRoutes = _.flatten(routes.filter(r => r.meta?.requiresAuth === false).map(r => [r.path, r.alias].filter(x => x)))
  .filter(r => !['/2fa', '/2fa-setup'].includes(r))

export default (() => {
  return {
    refreshRoute: '/user/refresh',
    unAuthenticatedRoutes: [...noAuthRoutes], // 401 - exact match path
    unAuthorizedRoutes: [...noAuthRoutes, '/2fa'], // 403 - exact match path
    3: function () { // 3xx fall through handler
    },
    400: function () {
    },
    401: function () {
      let query = { }
      if (window.location.hostname === 'localhost') {
        const urlParams = new URLSearchParams(window.location.search)
        const firm = urlParams.get('firm')
        if (firm) query = { firm } // preserve firm param in local
      }
      router.push({ path: '/', query })
    },
    403: function () {
      // router.push({ path: '/unauthorized' })
    },
    4: function () { // 4xx fall through handler
    },
    5: function () { // 5xx fall through handler
    },
    x: function () { // global fall through handler
    }
  }
})()
