<template>
  <SingleColumn md="4" offset-md="4">
    <TwoFASetup
      @completed="handleTwoFASetupCompleted"
      :init-status="status"
    />
    <PToast id="error-toast">
      {{ toastText }}
    </PToast>
  </SingleColumn>
</template>

<script>

export default {
  name: 'TwoFASetupPage',
  data () {
    return {
      clientId: null,
      email: '',
      invitationCode: '',
      toastText: '',
      status: null,
      loading: true
    }
  },
  async mounted() {
    if (!this.status) {
      try {
        const userStatus = await this.$store.dispatch('auth/checkUserStatus')
        this.status = userStatus.status
      } catch (e) {
        console.error(e)
        this.back()
      }
    }
    if (this.status && this.status > 4) {
      this.next()
    }
    this.loading = false
  },
  methods: {
    next () {
      this.$router.push({ name: 'loginPage' })
    },
    back () {
      this.$router.push({ name: 'registration' })
    },
    handleTwoFASetupCompleted () {
      this.$router.push({ name: 'EmailVerificationPendingPage' })
    },
    showToast (text) {
      this.toastText = text
      this.$bvToast.show('error-toast')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
