<template>
  <div>
    <DocumentManager v-if="bootstrapLoaded" />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('user')
export default {
  name: 'MyDocumentsPage',
  computed: {
    ...mapState(['bootstrapLoaded']),
    ...mapGetters(['clientId', 'user/isActiveClient'])
  },
  methods: {
    ...mapActions(['bootstrapCall', 'identifyLogRocketUser'])
  },
  watch: {
    bootstrapLoaded: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.identifyLogRocketUser()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
