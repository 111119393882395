<template>
  <ConsentForm @after-submit="afterSubmit" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('consent')

export default {
  name: 'ConsentPage',
  computed: {
    ...mapGetters([
      'userConsentRequired'
    ])
  },
  methods: {
    ...mapActions([
      'initUserConsent'
    ]),
    afterSubmit() {
      this.$router.push({ name: 'Home' })
    }
  },
  async mounted() {
    await this.initUserConsent()
    if (!this.userConsentRequired) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
