<template>
  <SingleColumn md="6" offset-md="3">
    <PRow class="justify-content-md-center text-center mt-3">
      <PColumn>
        <h3>{{ $t('common:sorry') }}</h3>
        <div class="subtitle mt-3 mb-4">
          {{ $t('account:missing_permissions') }}
        </div>
        <PButton @click="toHomePage">
          {{ $t('nav:home') }} <span>&rarr;</span>
        </PButton>
      </PColumn>
    </PRow>
  </SingleColumn>
</template>

<script>
export default {
  name: 'UnauthorizedPage',
  methods: {
    toHomePage() {
      if (this.$store.state.authService.isAuth()) {
        this.$router.push({ path: '/home' })
      } else {
        this.$router.push({ path: '/' })
      }
    }
  }
}
</script>

<style>

</style>
