<template>
  <div>
    <TwoFA
      @verify="verify"
    />
  </div>
</template>

<script>
export default {
  name: 'TwoFAPage',
  methods: {
    goToLogin () {
      this.$router.push({ name: 'loginPage' })
    },
    verify (status) {
      if (status.success === true) {
        if (status.is_reset_password) {
          this.$router.push({ path: '/login' })
        } else {
          this.$router.push({ name: 'Home' })
        }
      } else {
        if (status.reason === 'suspended') {
          this.$router.push({ name: 'AccountSuspendedPage' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
