import store from '../../../store'

function getClientIdFromContext () {
  if (!store.hasModule('context')) {
    throw new Error('Could not get Auth0 client ID: context module not registered')
  }

  const firm = store.getters?.['context/getCurrentFirm']

  if (!firm) {
    throw new Error('Could not get Auth0 client ID: firm not set in context store')
  }

  const clientIds = JSON.parse(process.env.VUE_APP_AUTH0_INVESTOR_PORTAL_CLIENT_ID)

  return clientIds[firm]
}

function getListOfFirms () {
  const firms = process.env.VUE_APP_AUTH0_INVESTOR_PORTAL_SSO_ENABLED_FIRMS ?? ''
  if (!firms) {
    console.warn('AUTH0_INVESTOR_PORTAL_SSO_ENABLED_FIRMS not set. SSO will not be enabled.')
  }
  return firms.split(',')
}

export function getAuth0PluginOptions () {
  return {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: getClientIdFromContext(),
    ssoFirms: getListOfFirms()
  }
}
