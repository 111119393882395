import LoadScript from 'vue-plugin-load-script'
import Vue from 'vue'

import analyticsConfig from '../analytics/config'
import addressConfig from '../address/config'
import config from '../api/config'
import store from '../../store'
import { appName } from '../app/definitions'

Vue.use(LoadScript)

export default new function () {
  this.registerService = function (serviceId, service) {
    if (this[serviceId]) {
      console.log('ServiceId already registered - ' + serviceId)
    } else {
      this[serviceId] = service
      initService(serviceId, service, this)
    }
  }
}()

function initService (serviceId, service, registry) {
  if (serviceId === 'analytics') initAnalyticsService(service)
  if (serviceId === 'api') initApiService(service)
  if (serviceId === 'context') initContextService(service, registry)
  if (serviceId === 'state') initStateService(service, registry)
  if (serviceId === 'auth') initAuthService(service)
  if (serviceId === 'address') initAddressService(service)
}

function initAnalyticsService (AnalyticsService) {
  Vue.loadScript('/js/load-heap.js').then(_ => {
    const analyticsInstance = new AnalyticsService({ config: analyticsConfig, store })
    console.log({ analyticsInstance })
  })
}

async function initAddressService (AddressService) {
  const addressInstance = new AddressService({ config: addressConfig })
  await addressInstance.init({ store })
}

function initApiService (API) {
  const apiInstance = new API(config)
  store.dispatch('setAPI', { api: apiInstance })
}

function initContextService (ContextService, registry) {
  const config = { firmDetails: true }
  registry.context = new ContextService({ appName, store, config })
}

function initStateService (StateService, registry) {
  registry.stateManager = new StateService({
    config: {
      enableModal: true,
      enableNotification: true
    },
    store
  })
}
function initAuthService (Auth) {
  const authObj = new Auth(store.state.api)
  store.dispatch('setAuthService', { auth: authObj })
}
