<template>
  <SingleColumn md="4" offset-md="4">
    <PCard class="px-3 text-left">
      <div class="subtitle bold">
        {{ $t('common:sorry') }}
      </div>
      <div class="text-left subtitle mt-3 mb-4">
        {{ $t('nav:page_not_found') }}
      </div>
    </PCard>
  </SingleColumn>
</template>

<script>
export default {
  name: 'GenericErrorPage',
  data () {
    return { }
  }
}
</script>

<style scoped>

</style>
